
.form {
  @apply flex flex-col gap-4 justify-center;
}

.buttons {
  @apply my-1 flex justify-center text-white dark:text-white text-sm leading-4 ;

  .button {
    @apply px-4 py-2 rounded-lg bg-main-600 hover:bg-main-500 transition-all;
    &:disabled {
      @apply  opacity-60;
 
      cursor: not-allowed;
    }
  }
}


.field {
  @apply relative flex flex-col text-gray-900 dark:text-gray-200 text-sm leading-4;
  
  label {
    @apply dark:text-gray-400 font-medium leading-4 peer-disabled:cursor-not-allowed peer-disabled:opacity-70;
  }

  &.field-required label:after {
    content: ' *';
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea,
  select,
  .editor {
    @apply mt-2 mb-1 block w-full outline-0 border border-gray-300 focus:border-gray-400 hover:border-gray-400 rounded-lg bg-transparent
    dark:border-gray-700 dark:hover:border-gray-500 dark:focus:border-gray-500 dark:bg-gray-950/70 
    ;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea,
  select{
    @apply px-3 py-3 text-sm tracking-wide leading-4  ;
  }

  .error {
    @apply text-xs text-red-600 h-0 overflow-hidden transition-all;
  }

  &.field-error.field-touched {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea,
    select,
    .editor {
      @apply  border-red-600 text-red-600 ;   
    }

    .error {
      @apply h-3;
    }
  }


  select {
    @apply shadow-sm shadow-black/5;
    // @apply appearance-none ;
    // background: url('../assets/arrowDown.svg') no-repeat right 0.5rem center;

 

    option {
      @apply text-sm font-medium leading-4 ;
    }

    option:not([value=""]) {
      @apply text-gray-800 dark:text-gray-100;
    }

    &:has(option:checked:not([value=""])) {
      @apply text-gray-800 dark:text-gray-100;
    }

    @apply text-gray-400 dark:text-gray-300;
    option {
      @apply text-gray-400 dark:text-gray-300;
    }
  }


  // &.field-error.field-touched {
  //   input[type="text"],
  //   input[type="email"],
  //   input[type="number"],
  //   input[type="password"],
  //   select {
  //     border-color: #fbaaaa;
  //   }
  // }

  // &.field-valide {
  //   input[type="text"],
  //   input[type="email"],
  //   input[type="number"],
  //   input[type="password"],
  //   select {
  //     // border-color: #79cb75;
  //   }
  // }

  // label,
  // textarea::placeholder,
  // input[type="text"]::placeholder,
  // input[type="email"]::placeholder,
  // input[type="number"]::placeholder,
  // input[type="password"]::placeholder {
  //   @apply text-sm transition-opacity text-black tracking-wide ;
  // }

  // &.field-options {
  //   label {
  //     @apply text-main-800;
  //   }

  //   label:after,
  //   label:before {
  //     content: "";
  //     @apply w-6 h-6 absolute inset-0 border border-light rounded-full;
  //   }

  //   label:after {
  //     @apply scale-0 bg-main-800 transition-all;
  //   }

  //   input:checked + label:after {
  //     @apply scale-50;
  //   }
  // }


}


.field-editor {
  ul.toolbar {
    @apply  flex gap-3 px-4 py-3 bg-gray-100 dark:bg-gray-800  rounded-t-lg;

    > li {
      @apply text-base p-1 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300 cursor-pointer ;
      &:hover {
        @apply bg-main-200 dark:bg-main-600
      }
    }
  }

  .tiptap  {
    @apply px-4 py-4 dark:text-gray-200  ;

    p {
      @apply text-sm; 
    }

    token {
      @apply cursor-pointer font-bold text-main-500 dark:text-main-600;
      // box-shadow: 0px 0px 5px #00000033 inset;
      user-select: none;      /* Verhindert das Markieren per Maus */
      -webkit-user-select: none;
      -ms-user-select: none;
      &::selection {
        color: red;
        background: transparent;
      }
    }
  }
}